// All languages object
export const LANGUAGE = {
    ENGLISH: "en",
    HINDI: "hi",
    GUJARATI: "gu",
    BENGALI: "bn",
    TAMIL: "ta",
    MARATHI: "mr",
    TELUGU: "te",
    KANNADA: "kn",
    PUNJABI: "pa",
    MALAYALAM: "ml",
    PORTUGUESE: "pt",
    SPANISH: "es",
    FRENCH: "fr",
    GERMAN: "de",
    DUTCH: "nl",
    BULGARIAN: "bg",
    CROATIAN: "hr",
    GREEK: "el",
    CZECK: "cs",
    DANISH: "da",
    ESTONIAN: "et",
    FINNISH: "fi",
    SWEDISH: "sv",
    HUNGARIAN: "hu",
    ICELANDIC: "is",
    ITALIAN: "it",
    LATVIAN: "lv",
    LITHUANIAN: "lt",
    LUXEMBOURGISH: "lb",
    MALTESE: "mt",
    NORWEGIAN: "no",
    POLISH: "pl",
    ROMANIAN: "ro",
    SLOVAK: "sk",
    SLOVENIAN: "sl",
}

export const CONTINENT = {
    EUROPE: "eu",
}

export const EUROPE_COUNTRY = {
    AUSTRIA: "at",
    BELGIUM: "be",
    BULGARIA: "bg",
    CROATIA: "hr",
    CYPRUS: "cy",
    CZECH_REPUBLIC: "cz",
    DENMARK: "dk",
    ESTONIA: "ee",
    FINLAND: "fl",
    FRANCE: "fr",
    GREECE: "gr",
    HUNGARY: "hu",
    ICELAND: "is",
    IRELAND: "ie",
    ITALY: "it",
    LATVIA: "lv",
    LITHUANIA: "lt",
    LUXEMBOURG: "lu",
    MALTA: "mt",
    NETHERLANDS: "nl",
    NORWAY: "no",
    POLAND: "pl",
    PORTUGAL:"pt",
    ROMANIA: "ro",
    SLOVAKIA: "sk",
    SLOVENIA: "sl",
    SPAIN: "es",
    SWEDEN: "se",
    SWITZERLAND: "ch",
}

// All country object
export const COUNTRY = {
    INDIA: "in",
    US: "us",
    BRAZIL: "br",
    MEXICO: "mx",
    UNITED_KINGDOM: "gb",
    CANADA: "ca",
    SOUTH_AFRICA: "za",
    NIGERIA: "ng",
    COLOMBIA: "co",
    GERMANY: "de",
    ...EUROPE_COUNTRY
}

// LANGUAGE + COUNTRY CONSTANT
export const LANGUAGE_COUNTRY = {
    /** {@link COUNTRY.INDIA} **/
    EN_IN: `${LANGUAGE.ENGLISH}-${COUNTRY.INDIA}`,
    HI_IN: `${LANGUAGE.HINDI}-${COUNTRY.INDIA}`,
    GU_IN: `${LANGUAGE.GUJARATI}-${COUNTRY.INDIA}`,
    BN_IN: `${LANGUAGE.BENGALI}-${COUNTRY.INDIA}`,
    TA_IN: `${LANGUAGE.TAMIL}-${COUNTRY.INDIA}`,
    MR_IN: `${LANGUAGE.MARATHI}-${COUNTRY.INDIA}`,
    TE_IN: `${LANGUAGE.TELUGU}-${COUNTRY.INDIA}`,
    KN_IN: `${LANGUAGE.KANNADA}-${COUNTRY.INDIA}`,
    PA_IN: `${LANGUAGE.PUNJABI}-${COUNTRY.INDIA}`,
    ML_IN: `${LANGUAGE.MALAYALAM}-${COUNTRY.INDIA}`,

    /** {@link COUNTRY.US} **/
    EN_US: `${LANGUAGE.ENGLISH}-${COUNTRY.US}`,
    ES_US: `${LANGUAGE.SPANISH}-${COUNTRY.US}`,

    /** {@link COUNTRY.GERMANY} **/
    EN_DE: `${LANGUAGE.ENGLISH}-${COUNTRY.GERMANY}`,
    DE_DE: `${LANGUAGE.GERMAN}-${COUNTRY.GERMANY}`,

    /** {@link COUNTRY.BRAZIL} **/
    EN_BR: `${LANGUAGE.ENGLISH}-${COUNTRY.BRAZIL}`,
    PT_BR: `${LANGUAGE.PORTUGUESE}-${COUNTRY.BRAZIL}`,

    /** {@link COUNTRY.MEXICO} **/
    EN_MX:  `${LANGUAGE.ENGLISH}-${COUNTRY.MEXICO}`,
    ES_MX:  `${LANGUAGE.SPANISH}-${COUNTRY.MEXICO}`,

    /** {@link COUNTRY.UNITED_KINGDOM} **/
    EN_GB:  `${LANGUAGE.ENGLISH}-${COUNTRY.UNITED_KINGDOM}`,

    /** {@link COUNTRY.CANADA} **/
    EN_CA:  `${LANGUAGE.ENGLISH}-${COUNTRY.CANADA}`,
    FR_CA:  `${LANGUAGE.FRENCH}-${COUNTRY.CANADA}`,

    /** {@link COUNTRY.SOUTH_AFRICA} **/
    EN_ZA:  `${LANGUAGE.ENGLISH}-${COUNTRY.SOUTH_AFRICA}`,

    /** {@link COUNTRY.NIGERIA} **/
    EN_NG:  `${LANGUAGE.ENGLISH}-${COUNTRY.NIGERIA}`,

    /** {@link COUNTRY.COLOMBIA} **/
    EN_CO:  `${LANGUAGE.ENGLISH}-${COUNTRY.COLOMBIA}`,
    ES_CO:  `${LANGUAGE.SPANISH}-${COUNTRY.COLOMBIA}`,
}

export const LANGUAGE_CONTINENT = {
    /** {@link CONTINENT.EUROPE} **/
    EN_EU: `${LANGUAGE.ENGLISH}-${CONTINENT.EUROPE}`,
}

export const LANGUAGE_COUNTRY_EXTRA = {
    /** {@link COUNTRY.AUSTRIA} **/
    DE_AT:  `${LANGUAGE.GERMAN}-${COUNTRY.AUSTRIA}`,

    /** {@link COUNTRY.BELGIUM} **/
    FR_BE:  `${LANGUAGE.FRENCH}-${COUNTRY.BELGIUM}`,
    DE_BE:  `${LANGUAGE.GERMAN}-${COUNTRY.BELGIUM}`,
    NL_BE:  `${LANGUAGE.DUTCH}-${COUNTRY.BELGIUM}`,

    /** {@link COUNTRY.BULGARIA} **/
    BG_BG:  `${LANGUAGE.BULGARIAN}-${COUNTRY.BULGARIA}`,

    /** {@link COUNTRY.CROATIA} **/
    HR_HR:  `${LANGUAGE.CROATIAN}-${COUNTRY.CROATIA}`,

    /** {@link COUNTRY.CYPRUS} **/
    EL_CY:  `${LANGUAGE.GREEK}-${COUNTRY.CYPRUS}`,

    /** {@link COUNTRY.CZECH_REPUBLIC} **/
    CS_CZ:  `${LANGUAGE.CZECK}-${COUNTRY.CZECH_REPUBLIC}`,

    /** {@link COUNTRY.DENMARK} **/
    DA_DK:  `${LANGUAGE.DANISH}-${COUNTRY.DENMARK}`,

    /** {@link COUNTRY.ESTONIA} **/
    ET_EE:  `${LANGUAGE.ESTONIAN}-${COUNTRY.ESTONIA}`,

    /** {@link COUNTRY.FINLAND} **/
    FI_FI:  `${LANGUAGE.FINNISH}-${COUNTRY.FINLAND}`,
    SV_FI:  `${LANGUAGE.SWEDISH}-${COUNTRY.FINLAND}`,

    /** {@link COUNTRY.FRANCE} **/
    FR_FR:  `${LANGUAGE.FRENCH}-${COUNTRY.FRANCE}`,

    /** {@link COUNTRY.GREECE} **/
    EL_GR:  `${LANGUAGE.GREEK}-${COUNTRY.GREECE}`,

    /** {@link COUNTRY.HUNGARY} **/
    HU_HU:  `${LANGUAGE.HUNGARIAN}-${COUNTRY.HUNGARY}`,

    /** {@link COUNTRY.ICELAND} **/
    IS_IS:  `${LANGUAGE.ICELANDIC}-${COUNTRY.ICELAND}`,

    /** {@link COUNTRY.IRELAND} **/
    EN_IE:  `${LANGUAGE.ENGLISH}-${COUNTRY.IRELAND}`,

    /** {@link COUNTRY.ITALY} **/
    IT_IT:  `${LANGUAGE.ITALIAN}-${COUNTRY.ITALY}`,

    /** {@link COUNTRY.LATVIA} **/
    LV_LV:  `${LANGUAGE.LATVIAN}-${COUNTRY.LATVIA}`,

    /** {@link COUNTRY.LITHUANIA} **/
    IT_LT:  `${LANGUAGE.LITHUANIAN}-${COUNTRY.LITHUANIA}`,

    /** {@link COUNTRY.LUXEMBOURG} **/
    FR_LU:  `${LANGUAGE.FRENCH}-${COUNTRY.LUXEMBOURG}`,
    DE_LU:  `${LANGUAGE.GERMAN}-${COUNTRY.LUXEMBOURG}`,
    LB_LU:  `${LANGUAGE.LUXEMBOURGISH}-${COUNTRY.LUXEMBOURG}`,

    /** {@link COUNTRY.MALTA} **/
    EN_FI:  `${LANGUAGE.ENGLISH}-${COUNTRY.MALTA}`,
    MT_FI:  `${LANGUAGE.MALTESE}-${COUNTRY.MALTA}`,

    /** {@link COUNTRY.NETHERLANDS} **/
    NL_NL:  `${LANGUAGE.DUTCH}-${COUNTRY.NETHERLANDS}`,

    /** {@link COUNTRY.NORWAY} **/
    NO_NO:  `${LANGUAGE.NORWEGIAN}-${COUNTRY.NORWAY}`,

    /** {@link COUNTRY.POLAND} **/
    PL_PL:  `${LANGUAGE.POLISH}-${COUNTRY.POLAND}`,

    /** {@link COUNTRY.PORTUGAL} **/
    PT_PT:  `${LANGUAGE.PORTUGUESE}-${COUNTRY.PORTUGAL}`,

    /** {@link COUNTRY.ROMANIA} **/
    RO_RO:  `${LANGUAGE.ROMANIAN}-${COUNTRY.ROMANIA}`,

    /** {@link COUNTRY.SLOVAKIA} **/
    SK_SK:  `${LANGUAGE.SLOVAK}-${COUNTRY.SLOVAKIA}`,

    /** {@link COUNTRY.SLOVENIA} **/
    SL_SL:  `${LANGUAGE.SLOVENIAN}-${COUNTRY.SLOVENIA}`,

    /** {@link COUNTRY.SPAIN} **/
    ES_ES:  `${LANGUAGE.SPANISH}-${COUNTRY.SPAIN}`,

    /** {@link COUNTRY.SWEDEN} **/
    SV_SE:  `${LANGUAGE.SWEDISH}-${COUNTRY.SWEDEN}`,

    /** {@link COUNTRY.SWITZERLAND} **/
    GE_CH:  `${LANGUAGE.GERMAN}-${COUNTRY.SWITZERLAND}`,
    FR_CH:  `${LANGUAGE.FRENCH}-${COUNTRY.SWITZERLAND}`,
    IT_CH:  `${LANGUAGE.ITALIAN}-${COUNTRY.SWITZERLAND}`,
}

// ALL LANGUAGE + COUNTRY ARRAY
export const allLocales : string[] = Object.values({...LANGUAGE_COUNTRY, ...LANGUAGE_CONTINENT});
export const localeArrayMinLanguage = [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN];

const getCountryLocales = (country: string): Array<string> => {
    return allLocales.filter(item => item.includes(country))
}

// ALL INDIAN LANGUAGES ARRAY
export const INDIA_LOCALES = getCountryLocales(COUNTRY.INDIA);
export const US_LOCALES = getCountryLocales(COUNTRY.US);
export const GERMANY_LOCALES = getCountryLocales(COUNTRY.GERMANY);
export const BRAZIL_LOCALES = getCountryLocales(COUNTRY.BRAZIL);
export const MEXICO_LOCALES = getCountryLocales(COUNTRY.MEXICO);
export const UNITED_KINGDOM_LOCALES = getCountryLocales(COUNTRY.UNITED_KINGDOM);
export const CANADA_LOCALES = getCountryLocales(COUNTRY.CANADA);
export const SOUTH_AFRICA_LOCALES = getCountryLocales(COUNTRY.SOUTH_AFRICA);
export const NIGERIA_LOCALES = getCountryLocales(COUNTRY.NIGERIA);
export const COLOMBIA_LOCALES = getCountryLocales(COUNTRY.COLOMBIA);

export const languageTextMapping = new Map<string, string>([
    [LANGUAGE.ENGLISH, "ENGLISH"],
    [LANGUAGE.HINDI, "हिन्दी"],
    [LANGUAGE.MARATHI, "मराठी"],
    [LANGUAGE.TELUGU, "తెలుగు"],
    [LANGUAGE.GUJARATI, "ગુજરાતી"],
    [LANGUAGE.BENGALI, "বাংলা"],
    [LANGUAGE.KANNADA, "ಕನ್ನಡ"],
    [LANGUAGE.PUNJABI, "ਪੰਜਾਬੀ"],
    [LANGUAGE.TAMIL, "தமிழ்"],
    [LANGUAGE.MALAYALAM, "മലയാള"],
    [LANGUAGE.PORTUGUESE, "PORTUGUÊS"],
    [LANGUAGE.SPANISH, "español"],
    [LANGUAGE.FRENCH, "française"],
    [LANGUAGE.GERMAN, "DEUTSCH"]
])

export const countryTextMapping = new Map<string, string>([
    [COUNTRY.INDIA, "INDIA"],
    [COUNTRY.US, "USA"],
    [COUNTRY.GERMANY, "GERMANY"],
    [COUNTRY.BRAZIL, "BRAZIL"],
    [COUNTRY.UNITED_KINGDOM, "UNITED KINGDOM"],
    [COUNTRY.CANADA, "CANADA"],
    [COUNTRY.SOUTH_AFRICA, "SOUTH AFRICA"],
    [COUNTRY.NIGERIA, "NIGERIA"],
    [COUNTRY.COLOMBIA, "COLOMBIA"]
])

export const defaultLocaleByCountry = new Map<string, string>([
    [COUNTRY.INDIA, LANGUAGE_COUNTRY.EN_IN],
    [COUNTRY.US, LANGUAGE_COUNTRY.EN_US],
    [COUNTRY.GERMANY, LANGUAGE_COUNTRY.DE_DE],
    [COUNTRY.BRAZIL, LANGUAGE_COUNTRY.PT_BR],
    [COUNTRY.UNITED_KINGDOM, LANGUAGE_COUNTRY.EN_GB],
    [COUNTRY.CANADA, LANGUAGE_COUNTRY.FR_CA],
    [COUNTRY.SOUTH_AFRICA, LANGUAGE_COUNTRY.EN_ZA],
    [COUNTRY.NIGERIA, LANGUAGE_COUNTRY.DE_DE],
    [COUNTRY.COLOMBIA, LANGUAGE_COUNTRY.EN_NG],
    [COUNTRY.MEXICO, LANGUAGE_COUNTRY.ES_MX],
    [COUNTRY.AUSTRIA, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.BELGIUM, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.BULGARIA, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.CROATIA, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.CYPRUS, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.CZECH_REPUBLIC, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.DENMARK, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.ESTONIA, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.FINLAND, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.FRANCE, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.GREECE, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.HUNGARY, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.ICELAND, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.IRELAND, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.ITALY, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.LATVIA, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.LITHUANIA, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.LUXEMBOURG, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.MALTA, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.NETHERLANDS, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.NORWAY, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.POLAND, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.PORTUGAL, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.ROMANIA, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.SLOVAKIA, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.SLOVENIA, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.SPAIN, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.SWEDEN, LANGUAGE_CONTINENT.EN_EU],
    [COUNTRY.SWITZERLAND, LANGUAGE_CONTINENT.EN_EU],

])

export const dataLocaleByContinent = new Map<string,string>([
    [LANGUAGE_CONTINENT.EN_EU, LANGUAGE_COUNTRY.EN_DE]
])