import {IAppVersionItem, ICommonData, IMainPageConfigObject, IMappingObjectData, IMappingObjectDataSubPages, ISubPageConfigObject, ISubPageLocaleData} from "../../types/common";
import {FORM_ERRORS, FORM_REGEX} from "../constants/GrievanceForm";
import {currentPageURL, getCountryFromLocale, getUrlWithScheme, pageToRolloutChannelMapping} from "../constants/PagesConstants";
import {GRIEVANCE_FORM_TYPES} from "../enums/enums";
import {COUNTRY, EUROPE_COUNTRY} from "../constants/LocaleAndCountryConstants";
import { BIT_32, BIT_64, GET_APP_MIGR_URL, GET_APP_PLAY_URL, GET_APP_US_URL, PLAYSTORE_PAGE, PLAYSTORE_PAGE2, THE_TECH_TRIUMPH_URL } from "../constants/GlobalConstants";
import { getWebsiteEnvironment, WINZO_JOGOS_DOT_COM_BR, WINZO_GAMES_DOT_US, WINZO_JUEGOS_DOT_MX, WINZO_JUEGOS_DOT_COM_CO, WINZO_SPIELE_DOT_DE, LIVE_WEB_URL } from '../constants/EnvironmentConstants';
import { Environment } from '../../types/Misc.type';
import { CommunicationRequestObject, IFireDeepLinkObject, IQuitWebViewObject, IShowHideLoadingObject } from '../../types/Fairplay-Insights';

export let buttonClickType = ""

export const setButtonClickType = (type: string) => {
  buttonClickType = type
}

export const validateForm = (data, error, formData) => {
    let hasError = error || false;
    if (data.type == GRIEVANCE_FORM_TYPES.FILE) {
        if (
            data.required &&
            (data.value == undefined || data.value.length === 0)
        ) {
            data.error = FORM_ERRORS.REQUIRED_ERROR_MESSAGE;
            hasError = true;
        } else {
            data.error = "";
        }
        formData.push({
            question: data.label,
            answer: data.value,
            attachments: data.attachments,
            type: data.type,
            required: data.required
        });
    } else if (data.type == GRIEVANCE_FORM_TYPES.FORM) {
        data.attributes.forEach((el) => {
            [hasError, formData] = validateForm(el, hasError, formData) || [
                hasError,
                formData,
            ];
        });

        if (data.children) {
            const [childHasError, childData] = validateForm(data.children, hasError, formData)
            hasError = hasError || childHasError;
            formData = childData
        }

    } else if (data.type == GRIEVANCE_FORM_TYPES.RADIO) {
        if (data.required && data.selected == undefined) {
            data.error = FORM_ERRORS.REQUIRED_ERROR_MESSAGE;
            hasError = true;
        } else {
            data.error = "";
        }
        formData.push({question: data.label, answer: data.value, type: data.type, required: data.required});
        if (data.selected != undefined && data.options[data.selected].children) {
            [hasError, formData] = validateForm(
                data.options[data.selected].children,
                hasError,
                formData
            ) || [hasError, formData];
        }
    } else {
        if (data.required && (data.value == undefined || data.value == "")) {
            data.error = FORM_ERRORS.REQUIRED_ERROR_MESSAGE;
            hasError = true;
        } else {
            if (
                data.type == GRIEVANCE_FORM_TYPES.EMAIL &&
                !FORM_REGEX.EMAIL.test(data.value)
            ) {
                hasError = true;
                data.error = FORM_ERRORS.EMAIL_ERROR_MESSAGE;
            } else if (
                data.type == GRIEVANCE_FORM_TYPES.MOBILE &&
                !FORM_REGEX.MOBILE.test(data.value)
            ) {
                hasError = true;
                data.error = FORM_ERRORS.MOBILE_ERROR_MESSAGE;
            } else if (
                data.type == GRIEVANCE_FORM_TYPES.URL &&
                !FORM_REGEX.URL.test(data.value)
            ) {
                hasError = true;
                data.error = FORM_ERRORS.URL_ERROR_MESSAGE;
            } else if (
                data.type == GRIEVANCE_FORM_TYPES.GOV_EMAIL &&
                !isGovEmail(data.value)
            ) {
                hasError = true;
                data.error = FORM_ERRORS.EMAIL_ERROR_MESSAGE;
            } else {
                data.error = "";
            }
        }
        if (data.type !== GRIEVANCE_FORM_TYPES.PLAINTEXT) {
            let value = (data.type === GRIEVANCE_FORM_TYPES.DATE || data.type === GRIEVANCE_FORM_TYPES.DATETIME) ? new Date(data.value).toString() : data.value
            formData.push({question: data.label, answer: value, type: data.type, required: data.required});
        }
    }
    return [hasError, formData];
};

const isGovEmail = (email: string) => {
    if (FORM_REGEX.EMAIL.test(email) && (email.includes('nic.in') || email.includes('gov.in'))) {
        return true
    }
    return false
}

export const getFileNameFromUrl = (file: string) => {
    let fileNameArray = file.replace(/^.*[\\\/]/, "").split('_')
    return fileNameArray[fileNameArray.length - 1];
};

export const showHowToInstallVideo = (commonData: ICommonData, locale: string, utm?: string | string[] | undefined| null) => {
    if (
        currentPageURL !== PLAYSTORE_PAGE && currentPageURL !== PLAYSTORE_PAGE2 && currentPageURL !== GET_APP_PLAY_URL  && currentPageURL !== GET_APP_MIGR_URL && currentPageURL !== GET_APP_US_URL &&
        //TODO
        (commonData?.howToInstallVideo || commonData?.howToInstall?.howToInstallVideo)
    ) {
        return true;
    }
    return false;
}

export const showHowToInstallStory = (commonData: ICommonData, content: any) => {
    if (
        !pageToRolloutChannelMapping.PAID_APP.includes(currentPageURL) &&
        !pageToRolloutChannelMapping.PAID_APP_2.includes(currentPageURL) &&
        !pageToRolloutChannelMapping.AFFILIATE_APP.includes(currentPageURL) &&
        !pageToRolloutChannelMapping.ORGANIC_APP.includes(currentPageURL) && 
        (content.howToInstallStory || commonData.howToInstallStory) 
    ) {
        return true;
    }
    return false;
}

export const multipleClassName = (...classNames: string[]): string => {
  return classNames.join(" ")
}

export const getLogoUrl = (locale?: string, pageName?: string) => {
    if (pageName?.includes(THE_TECH_TRIUMPH_URL)) {
        return "https://d3g4wmezrjkwkg.cloudfront.net/website/images/ttt-revamp/ttt-logo.webp";
    }

  if (getCountryFromLocale(locale) === COUNTRY.BRAZIL || getCountryFromLocale(locale) === COUNTRY.GERMANY) {
      return "https://d3g4wmezrjkwkg.cloudfront.net/website/images/logoAsset_br.webp"
  } else {
      return "https://d3g4wmezrjkwkg.cloudfront.net/website/images/logo-gold.png"
  }
}

export const getDynamicString = (field: string, stringKey: string) => {
    return stringKey.replace("%s", field)
}

export const checkUppercase = (str: string) => {
    return /[A-Z]/.test(str);
};

export const getOneLinkClickUrl = (clickUrl: string, url: string): string => {
    if (clickUrl) {
        const oneLinkUrl = new URL(clickUrl)
        oneLinkUrl.searchParams.set("af_android_url", url)
        return oneLinkUrl.toString()
    } else {
        return clickUrl
    }
}

export const getDomainEnvForLocale = (country: string): Environment => {
    let basePath = getUrlWithScheme(getWebsiteEnvironment().baseUrl);

    if (country === COUNTRY.BRAZIL) {
        basePath = getUrlWithScheme(WINZO_JOGOS_DOT_COM_BR);
    } else if (country === COUNTRY.US || Object.values(EUROPE_COUNTRY).includes(country)) {
        basePath = getUrlWithScheme(WINZO_GAMES_DOT_US);
    } else if (country === COUNTRY.MEXICO) {
        basePath = getUrlWithScheme(WINZO_JUEGOS_DOT_MX);
    } else if (country === COUNTRY.COLOMBIA) {
        basePath = getUrlWithScheme(WINZO_JUEGOS_DOT_COM_CO);
    } else if (country === COUNTRY.GERMANY) {
        basePath = getUrlWithScheme(WINZO_SPIELE_DOT_DE);
    } else {
        basePath = getUrlWithScheme(LIVE_WEB_URL);
    }
    return getWebsiteEnvironment(basePath)
}

export const mapGamePages = (gamePageConfig: IMainPageConfigObject): Map<string, IMappingObjectData> => {
    const gamePagesWithLocale = new Map<string, IMappingObjectData>();
    Object.keys(gamePageConfig).forEach((key) => {
        const locales = gamePageConfig[key];
        gamePagesWithLocale.set(key, {
            locales: locales
        });
    });
    return gamePagesWithLocale;
}

export const mapSubGamePages = (subGamePageConfig: ISubPageConfigObject): Map<string, IMappingObjectDataSubPages> => {
    const subGamePagesWithLocale = new Map<string, IMappingObjectDataSubPages>();
    Object.keys(subGamePageConfig).forEach((key) => {
        const subPagesDetail = subGamePageConfig[key];
        const subPages = new Array<ISubPageLocaleData>();
        Object.keys(subPagesDetail).forEach((subPage) => {
            const locales = subPagesDetail[subPage];
            subPages.push({
                subPage: subPage,
                localeData: {
                    locales: locales
                }
            });
        });
        subGamePagesWithLocale.set(key, {
            subPages: subPages
        });  
    });
    return subGamePagesWithLocale;
}

export const hideLoader = (isAndroidWebView: boolean, isIosWebView: boolean,) => {
    const hideLoaderRequest: IShowHideLoadingObject = {
        name: 'hideLoading',
    };

    const hideLoaderCallback = (reqObj: CommunicationRequestObject) => {
        return window.ApiInterface.hideLoading();
    };
    handleBridgeCommunication(hideLoaderCallback, hideLoaderRequest, isAndroidWebView, isIosWebView);
};

export  const quitWebView = (isAndroidWebView: boolean, isIosWebView: boolean,) => {
    const quitWebViewRequest: IQuitWebViewObject = {
        name: 'quitWebView',
    };

    const quitWebViewCallback = (reqObj: CommunicationRequestObject) => {
        return window.ApiInterface.quitWebView();
    };
    handleBridgeCommunication(quitWebViewCallback, quitWebViewRequest, isAndroidWebView, isIosWebView);
}

export const fireDeepLink = (url: string, isAndroidWebView: boolean, isIosWebView: boolean,) => {
    const fireDeepLinkRequest: IFireDeepLinkObject = {
        name: 'fireDeepLink',
        url: url,
        closeActivity: true,
    };

    const fireDeepLinkCallback = (reqObj: CommunicationRequestObject) => {
        const requestObject = reqObj as IFireDeepLinkObject;
        return window.ApiInterface.fireDeepLink(requestObject.url, requestObject.closeActivity );
    };
    handleBridgeCommunication(fireDeepLinkCallback, fireDeepLinkRequest, isAndroidWebView, isIosWebView);
}

export const handleBridgeCommunication = (
    callback: (reqObj: CommunicationRequestObject) => any,
    requestObject: CommunicationRequestObject,
    isAndroidWebView: boolean,
    isIosWebView: boolean,
): Promise<any> => {
    if (isAndroidWebView) {
        return new Promise((resolve) => {
            setTimeout(() => {
                const data = callback(requestObject);
                const parsedData = data ? JSON.parse(data) : data;
                resolve(parsedData);
            }, 0);
        });
    } else if (isIosWebView) {
        return window.webkit.messageHandlers.iOSBridgeV2.postMessage(requestObject);
    } else {
        return new Promise((resolve) => {
            resolve("No native Bridge found");
        });
    }
};
export const getApkUrlByVersion = (version: IAppVersionItem, arch: string | undefined): string => {
    let vArch: string = "vu"
    if (arch === BIT_32) {
        vArch = "v7"
    } else if (arch === BIT_64) {
        vArch = "v8"
    }

    if (version.url) {
        return version.url
    } else {
        const pageAppUrl = `https://d1z38n2ru3n165.cloudfront.net/ver.${version.version}/${vArch}/WinZO.apk`
        const url = new URL(pageAppUrl);
        url.searchParams.append("arch", vArch);
        return url.toString();
    }
}
